// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap";

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  //Expose jquery as global variable
  global.$ = window.$ = $;
});

//IE CHECK
if (typeof window.navigator !== undefined && /Trident\/|MSIE /.test(window.navigator.userAgent)) {
  if (typeof window.location !== undefined && window.location.href.indexOf("/en/") > -1) {
    alert(
      "This site does not work properly with Internet Explorer. Please use Microsoft Edge, Google Chrome or Mozilla Firefox."
    );
  } else {
    alert(
      "Tämä sivusto ei toimi oikein Internet Explorerin kanssa. Jos mahdollista käytä Microsoft Edgeä, Google Chromea tai Mozilla Firefoxia."
    );
  }
}
